import React, { Fragment, useEffect } from "react"

/* Import Global Components */
import SEO from "~components/seo"

const FourOhFour = () => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--color-background",
      "rgb(227, 96, 128)"
    )
    document.documentElement.style.setProperty(
      "--color-foreground",
      " rgb(254, 220, 48)"
    )
  }, [])

  return (
    <Fragment>
      <SEO title="404" />
    </Fragment>
  )
}

export default FourOhFour
